<template>
  <form @input="$emit('input', formattedLetterWords)">
    <div v-for="(item, i) in letterWords" :key="i">
      <input type="text" v-model="item.answer" placeholder="Answer" />

      <div class="ml-40">
        <input
          type="text"
          v-model="item.emptyNumbers"
          placeholder="Empty numbers. Ex: 1, 2, 3"
        />
        <input
          type="text"
          v-model="item.words"
          placeholder="Words. Ex: have, has, nope"
        />
      </div>
    </div>

    <button type="button" @click="addItem">
      + Add Item
    </button>
  </form>
</template>

<script>
// "letterWords": [
// {
//   "words": [
//     "cloud",
//     "good",
//     "much"
//   ],
//   "answer": "I love apple very much",
//   "emptyNumbers": [
//     2,
//     5
//   ]
// },
// ]
export default {
  name: 'Type6a',
  data() {
    return {
      letterWords: [
        {
          answer: '',
          words: '',
          emptyNumbers: '',
        },
      ],
    };
  },
  computed: {
    formattedLetterWords() {
      let result = JSON.parse(JSON.stringify(this.letterWords)).map((lw) => {
        lw.emptyNumbers = lw.emptyNumbers
          .replace(/[\,\/]/gi, ',')
          .split(',')
          .map((v) => +v);
        lw.words = lw.words
          .replace(/\s/g, '')
          .replace(/[\,\/]/gi, ',')
          .split(',');
        return lw;
      });
      return result;
    },
  },
  methods: {
    addItem() {
      this.letterWords.push({
        answer: '',
        words: '',
        emptyNumbers: '',
      });
    },
  },
};
</script>
